import React from "react";
// @ts-ignore
import Img6 from "../assets/img/aisol-img6.png";
// @ts-ignore
import bgimg7 from "../assets/img/ai-bg7.png";
import "./Why/Why.scss";
// @ts-ignore
import WhyCard from "../components/WhyCard";
// @ts-ignore
import employeeIcon from "../assets/img/why/1.png";
// @ts-ignore
import increaseIcon from "../assets/img/why/2.png";
// @ts-ignore
import retentionIcon from "../assets/img/why/3.png";
// @ts-ignore
import transformIcon from "../assets/img/why/4.png";
// @ts-ignore
import creatingIcon from "../assets/img/why/5.png";
// @ts-ignore
import diversityIcon from "../assets/img/why/6.png";
// @ts-ignore
import drivingIcon from "../assets/img/why/7.png";

const AIPersonalized = () => {
  return (
    <div
      className="section bg-warm"
      style={{
        backgroundImage: `url(${bgimg7})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <h2 className="text-center mb-8" style={{ fontSize: 36 }}>
        Découvrez comment le coaching en IA de Coachello peut vous guider vers
        le succès{" "}
      </h2>
      <div className="Why-cardContainer">
        <div>
          <WhyCard
            icon={employeeIcon}
            title="Environnement de travail"
            text="Optimisez la dynamique de votre espace de travail"
            tooltip="Adopter un environnement de travail hybride en toute transparence.
Renforcez votre influence et votre réseau au sein de votre organisation.
Exploiter l'intelligence collective pour réussir en collaboration.
Maîtriser l'adaptabilité au changement dans des environnements en évolution rapide."
          />
        </div>
        <div>
          <WhyCard
            icon={creatingIcon}
            title="Leadership"
            text="Améliorez votre parcours de leadership "
            tooltip="Cultiver un leadership naturel.
Affiner les compétences de gestion pour un leadership percutant.
Adopter l'authenticité dans son style de leadership.
Développer une approche de manager-coach pour renforcer l'autonomie de l'équipe."
          />
        </div>
        <div>
          <WhyCard
            icon={drivingIcon}
            title="Qualité de vie"
            text="Améliorer l'épanouissement personnel"
            tooltip="Découvrir et poursuivre des intérêts personnels.
Stimuler la motivation pour un engagement durable.
Favoriser des relations positives au travail.
Parvenir à un équilibre harmonieux entre vie professionnelle et vie privée."
          />
        </div>
        <div>
          <WhyCard
            icon={retentionIcon}
            title="Stress et anxiété"
            text="Renforcer la résilience et le bien-être"
            tooltip="Gérer efficacement le stress et l'anxiété.
Améliorer le bien-être général pour des performances optimales.
Cultiver la résilience face aux défis.
Mettre en œuvre des stratégies de prévention de l'épuisement professionnel."
          />
        </div>
        <div>
          <WhyCard
            icon={increaseIcon}
            title="Communication"
            text="Maîtriser l'art de l'interaction"
            tooltip="
Développer l'assertivité pour une communication claire.
Améliorer l'empathie pour se rapprocher des autres.
Affiner les compétences d'écoute pour une meilleure compréhension.
Naviguer dans les conflits avec confiance et tact.
"
          />
        </div>
        <div>
          <WhyCard
            icon={transformIcon}
            title="Conscience de soi"
            text="Libérez votre potentiel"
            tooltip="Identifier et s'aligner sur les besoins pour fonctionner de manière optimale.
Clarifier et respecter ses valeurs fondamentales.
Explorer ses points forts pour s'épanouir.
Découvrir et traiter les points faibles pour un développement holistique."
          />
        </div>
        <div>
          <WhyCard
            icon={diversityIcon}
            title="Développement de carrière"
            text="Naviguez en toute confiance sur votre chemin de carrière"
            tooltip="Passer en douceur à de nouvelles opportunités de carrière.
Élaborer des stratégies pour une planification de carrière efficace.
Trouver un sens à son travail et s'y épanouir.
Surmonter le syndrome de l'imposteur et le doute de soi."
          />
        </div>
        <div>
          <WhyCard
            icon={creatingIcon}
            title="Performance professionnelle"
            text="Exceller dans votre rôle professionnel"
            tooltip="Maîtriser la prise de parole en public pour des présentations percutantes.
Stimuler la productivité pour obtenir de meilleurs résultats.
Gérer efficacement son temps pour une approche équilibrée.
Rechercher et utiliser le retour d'information et la reconnaissance pour une amélioration continue."
          />
        </div>
      </div>
    </div>
  );
};

export default AIPersonalized;
